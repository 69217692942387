module.exports = {
  siteMetadata: {
    title: 'Baskets Grundschul-Challenge',
    description: 'Baskets Grundschul-Challenge',
    siteUrl: 'https://baskets-grundschul-challenge.de',
    author: '© Copyright',
    twitter: '',
    adsense: '',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-prismic',
      options: {
        repositoryName: 'baskets',
        // accessToken: `${process.env.API_KEY}`,
        accessToken: `MC5YTlZMcUJBQUFGMGxmZ0RB.LBzvv70s77-9cu-_ve-_ve-_vRwg77-9Le-_vUIq77-977-977-977-9LUdI77-9Pe-_vUjvv70w77-977-977-9`,
        schemas: {
          media: require('./src/schemas/media.json'),
          news_challenge: require('./src/schemas/news_challenge.json'),
          news_liga: require('./src/schemas/news_liga.json'),
          page_settings: require('./src/schemas/page_settings.json'),
          projekt_challenge: require('./src/schemas/projekt_challenge.json'),
          projekt_liga: require('./src/schemas/projekt_liga.json'),
          teilnehmer: require('./src/schemas/teilnehmer.json'),
          termine_challenge: require('./src/schemas/termine_challenge.json'),
          termine_liga: require('./src/schemas/termine_liga.json'),
        },
        // Get the correct URLs in blog posts
        linkResolver: () => (post) => `/${post.uid}`,
        // PrismJS highlighting for labels and slices
        // htmlSerializer: () => prismicHtmlSerializer,
        // Remove this config option if you only have one language in your Prismic repository
        // lang: 'en-gb',
      },
    },
    {
      resolve: `gatsby-plugin-portal`,
      options: {
        key: 'portal',
        id: 'portal',
      },
    },
    // {
    //   resolve: 'gatsby-source-filesystem',
    //   options: {
    //     path: `${__dirname}/content/posts/`,
    //     name: 'posts',
    //   },
    // },
    // {
    //   resolve: 'gatsby-source-filesystem',
    //   options: {
    //     path: `${__dirname}/content/images/`,
    //     name: 'images',
    //   },
    // },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          'gatsby-remark-copy-linked-files',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Baskets Grundschul-Challenge',
        short_name: 'BGC',
        description: 'Baskets Grundschul-Challenge',
        homepage_url: 'https://baskets-grundschul-challenge.de',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#673ab7',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-transformer-sharp',
  ],
}
