import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import useToggle from './useToggle'

import logoChallenge from '../assets/images/Logopaket_BGSC_BGSL/04_BGSC_LOGO_Paket/BGSC_LOGO_3c.svg'
import logoLiga from '../assets/images/Logopaket_BGSC_BGSL/05_BGSL_LOGO_Paket/BGSL_LOGO_3c.svg'
import logoBonn from '../assets/images/STADT_BONN/BONN_Logo_Freude_rot_schw_4c.svg'
import logoBaskets from '../assets/images/Baskets_Bonn/Telekom Baskets Bonn_heller Hintergrund.svg'
import logoTelekom from '../assets/images/logoTelekom.svg'
import logoAOK from '../assets/images/logoAOK.svg'

const Projektinitiatoren = () => (
  <div className="d-flex jc-ai-center" style={{ height: '60px' }}>
    <a
      className="h-100 navbar-brand mr-2"
      href="https://www.telekom.com/de/konzern/themenspecials/bonn/bonn/telekom-baskets-bonn-336782"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={logoTelekom} style={{ height: '90%', width: 'auto' }} />
    </a>
    <a
      className="h-100 navbar-brand mx-2"
      href="https://www.telekom-baskets-bonn.de/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={logoBaskets} style={{ height: '90%', width: 'auto' }} />
    </a>
    <a
      className="h-100 navbar-brand mx-2"
      href="https://www.bonn.de/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={logoBonn} style={{ height: '100%', width: 'auto' }} />
    </a>
  </div>
)

const Partner = () => (
  <div className="d-flex jc-ai-center" style={{ height: '60px' }}>
    <a
      className="h-100 navbar-brand mr-2"
      href="https://www.aok.de"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={logoAOK} style={{ height: '90%', width: 'auto' }} />
    </a>
  </div>
)

const AnchorItems = ({ showTeilnehmer }) => {
  const classes = `nav-item nav-link ${showTeilnehmer ? 'mx-1_5' : 'mx-2'}`
  return (
    <>
      <AnchorItem name={'Projekt'} className={classes} />
      {showTeilnehmer && <AnchorItem name={'Teilnehmer'} className={classes} />}
      <AnchorItem name={'Termine'} className={classes} />
      <AnchorItem name={'Media'} className={classes} />
      <AnchorItem name={'Kontakt'} className={classes} />
    </>
  )
}

const AnchorItem = ({ name, ...props }) => (
  <AnchorLink offset="100" href={`#${name.toLowerCase()}`} {...props}>
    {name}
  </AnchorLink>
)

const LogoArea = ({ mobile, pathname }) => {
  const classes = 'flex-column align-items-start justify-content-center mr-3'
  return (
    <div className="d-flex">
      <div className={`${classes} ${mobile ? 'd-flex' : 'd-none d-md-flex'} `}>
        <p className="partner-title">Projektinitiatoren:</p>
        <Projektinitiatoren />
      </div>
      {pathname === '/challenge/' && (
        <div className={`${classes} ${mobile ? 'd-flex' : 'd-none d-md-flex'} `}>
          <p className="partner-title">Offizieller Partner:</p>
          <Partner />
        </div>
      )}
    </div>
  )
}

export default function Navi({ location, showTeilnehmer }) {
  const [open, toggle] = useToggle(false)
  // console.log(location)

  return (
    <nav className="navbar navbar-light bg-white w-100">
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Link to={'/challenge/'} className="navbar-brand mx-2">
            <img src={logoChallenge} height="80px" alt="Baskets Grundschul-Challenge" />
          </Link>
          <Link to={'/liga/'} className="navbar-brand mx-2">
            <img src={logoLiga} height="80px" alt="Baskets Grundschul-Liga" />
          </Link>
        </div>
        {(location.pathname === '/liga/' || location.pathname === '/challenge/') && (
          <>
            <button
              className={`d-md-none hamburger hamburger--spring ${open && 'is-active'}`}
              type="button"
              onClick={() => toggle()}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <div className="d-none d-md-flex align-items-center font-weight-bold">
              <AnchorItems showTeilnehmer={showTeilnehmer} />
            </div>
          </>
        )}
        <LogoArea pathname={location.pathname} mobile={false} />
      </div>
      {open && (
        <div
          className="w-100 pb-3 bg-white d-md-none d-flex flex-column align-items-end"
          style={{
            borderBottom: '1px solid rgb(226,0,116)',
            position: 'absolute',
            top: '108px',
            left: '0px',
            zIndex: '100000',
          }}
        >
          <AnchorItems showTeilnehmer={showTeilnehmer} />
          <hr />
          <LogoArea pathname={location.pathname} mobile={true} />
        </div>
      )}
    </nav>
  )
}
