import React, { useEffect } from 'react'
import Img from 'gatsby-image'
// import emergence from 'emergence.js'

import Navi from './Navi.js'
// import Footer from './Footer.js'
import { siteMetadata } from '../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import '../scss/gatstrap.scss'
// import 'react-html5video/dist/styles.css'

export default ({ children, fullScreen, ...props }) => {
  console.log(props)
  const pathn = props.location.pathname || 'none'
  // useEffect(() => {
  //   sal({
  //     threshold: 0.1,
  //   })
  // }, [])

  if (fullScreen === true) {
    return (
      <div
        className={`d-flex flex-column page-${pathn.split('/')[1]}`}
        style={{ height: '100vh' }}
      >
        <Navi title={siteMetadata.title} {...props} />
        {children}
        {/* <Footer title={siteMetadata.title} author={siteMetadata.author} /> */}
      </div>
    )
  } else {
    return (
      <div className={`d-flex flex-column page-${pathn.split('/')[1]}`}>
        <Navi title={siteMetadata.title} {...props} />
        {children}
        {/* <Footer title={siteMetadata.title} author={siteMetadata.author} /> */}
      </div>
    )
  }
}
